<template>
  <div>
    <div>
      <div class="bg-gray-100 dark:bg-gray-800">
        <div class="max-w-screen-xl mx-auto p-4">
          <div class="flex items">
            <div class="flex-1">
              <h1 class="text-2xl font-semibold dark:text-gray-100">
                <span class="text-gray-900">Choose a survey</span>
              </h1>
              <div>
                <select class="rounded-lg mt-2 mb-2" v-model="selected_survey_id">
                  <option selected>
                    -- Select a survey --
                  </option>
                  <option v-for="survey in surveys" :key="survey.id" :value="survey.id">
                    {{ survey.content.description }}
                  </option>
                </select>
              </div>
              <div>
                <h1 class="text-2xl font-semibold dark:text-gray-100">
                  <span class="text-gray-900">Enter survey/participant description</span>
                </h1>
                <p class="text-xs font-light">This can be used later to reference back to this survey</p>
                <input type="text" v-model="survey_description" class="rounded-lg mt-2 mb-2">
              </div>
              <div class="mt-4 mb-4">
                <button @click="goNext()" class="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-lg">
                  Next
                </button>
              </div>
              <div class="mt-4 mb-4">
                <button @click="back" class="bg-gray-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-lg">Back</button>
              </div>
            </div>
          </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
        db :null,
        survey_changeable : true,
        survey_description : null,
        selected_survey_id : null,
        surveys : null,
        next_id : null,
    }
  },
  computed: {
    online: function(){
      return this.$store.getters.online;
    },
    user_id : function(){
      return this.$store.getters.user_id;
    },
    token : function(){
      return this.$store.getters.token;
    },
    latitude : function(){
      return this.$store.getters.latitude;
    },
    longitude : function(){
      return this.$store.getters.longitude;
    }
  },
  mounted() {
    const request = window.indexedDB.open("SurveyDatabase", 2);
    request.onerror = (event) => {
      console.error("Database error: " + event.target.errorCode);
    };

    request.onsuccess = (event) => {
      this.db = event.target.result; // `this` refers to the Vue instance
      console.log("Database opened successfully");
      this.readSurveys();
      this.getId();
    };

    request.onupgradeneeded = (event) => {
      this.db = event.target.result;
      if (!this.db.objectStoreNames.contains("SurveyDataStore")) {
        this.db.createObjectStore("SurveyDataStore", { keyPath: "id" });
        console.log("Survey Object store created");
        this.getId();
      }
      if (!this.db.objectStoreNames.contains("AnswersDataStore")) {
        this.db.createObjectStore("AnswersDataStore", { keyPath: "id" });
        console.log("Answers Object store created");
        this.getId();
      }
    };
  },
  methods: {
    back(){
      this.$router.push({ name: "home"});
    },
    getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          console.log(position.coords.latitude, position.coords.longitude);
          this.$store.commit('UPDATE_LAT',position.coords.latitude);
          this.$store.commit('UPDATE_LNG',position.coords.longitude);
        });
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    },
    goNext(){
        this.getUserLocation();
        this.createSurvey(this.selected_survey_id);
        this.$router.push({ name: "edit-survey", params: { survey_id: this.next_id } });
    },
    readSurveys(){
      console.log("Reading Surveys");
      if (!this.db) {
        console.error("Database is not initialized");
        return;
      }
      const transaction = this.db.transaction(["SurveyDataStore"]);
      const objectStore = transaction.objectStore("SurveyDataStore");
      const request = objectStore.getAll();

      request.onerror = (event) => {
        console.error("Could not retrieve data: ", event.target.error);
      };

      request.onsuccess = () => {
        if (request.result) {
          this.surveys = request.result;
          console.log("Data retrieved: ", request.result);
        } else {
          console.log("No data found with id 1");
        }
      };
    },
    async getId(){
      console.log("Fetching next ID");
      if (!this.db) {
        console.error("Database is not initialized");
        return;
      }
      const transaction = this.db.transaction(["AnswersDataStore"]);
      const objectStore = transaction.objectStore("AnswersDataStore");
      const request = objectStore.getAll();

      request.onerror = (event) => {
        console.error("Could not retrieve data: ", event.target.error);
      };

      request.onsuccess = () => {
        if (request.result) {
          console.log("Data retrieved in next ID: ", request.result);
          this.next_id = request.result.length + 1;
          console.log(this.next_id);
        } else {
          console.log("No data found with id 1");
        }
      };
    },
    async createSurvey(){
        // Open a transaction as before
        const dbTransaction = this.db.transaction(["AnswersDataStore"], "readwrite");
        // eslint-disable-next-line no-unused-vars
        const store = dbTransaction.objectStore("AnswersDataStore");
        let item = JSON.parse(JSON.stringify(this.surveys.find(survey => survey.id === this.selected_survey_id)));
        item.id = this.next_id;
        item.content.form_items.forEach((form_item) => {
          form_item.answer = null;
          form_item.file = null;
          form_item.file_extension = null;
        });
        item.content.describing_name = this.survey_description;
        item.content.created_date = new Date();
        item.lat = this.latitude;
        item.lng = this.longitude;
        console.log("This is the item");
        console.log(item);
        console.log("That was the item");
        store.put(item);

        // Transaction complete handler
        dbTransaction.oncomplete = () => {
          console.log("Data added/updated in the store successfully");
        };

        // Transaction error handler
        dbTransaction.onerror = (event) => {
          console.error(event);
          console.error("Transaction error: ", event.target.error);
        };
    }
  }

}
</script>

