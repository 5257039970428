<template>
  <div>
    <div>
      <div v-if="showConfirmDialog" class="confirm-dialog overflow-y-auto overflow-x-hidden z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative p-4 w-full max-w-md max-h-full">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button @click="showConfirmDialog = false" type="button" class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
            <div class="p-4 md:p-5 text-center">
              <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
              </svg>
              <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Are you sure you want to push this survey?
              </h3>
              <h4 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                This will cause you to lose access to edit this survey
              </h4>
              <button  @click="confirmPush" type="button" class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                Yes, I'm sure
              </button>
              <button  @click="showConfirmDialog = false" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showDeleteDialog" class="confirm-dialog overflow-y-auto overflow-x-hidden z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative p-4 w-full max-w-md max-h-full">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button @click="showConfirmDialog = false" type="button" class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
            <div class="p-4 md:p-5 text-center">
              <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
              </svg>
              <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Are you sure you want to remove this survey?
              </h3>
              <h4 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                This will cause you to lose access to edit this survey
              </h4>
              <button  @click="confirmDelete" type="button" class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                Yes, I'm sure
              </button>
              <button  @click="showConfirmDialog = false" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-gray-100 dark:bg-gray-800">
        <div class="max-w-screen-xl mx-auto p-4">
          <div class="flex items">
            <div class="flex-1">
              <h5 class="text-2xl font-semibold dark:text-gray-100">
                <span class="text-gray-900">My Surveys</span>
              </h5>
              <h5 class="text-xl font-semibold dark:text-gray-100">
                <span class="text-gray-900">Choose a survey to Edit</span>
              </h5>
              <button @click="createSurvey()" class="bg-green-500 hover:bg-green-600 text-white font-semibold px-4 py-2 rounded-lg">
                Do New Survey
              </button>
              <div class="relative overflow-x-auto">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <th scope="col" class="px-6 py-3 border-r">
                      Description
                    </th>
                    <th scope="col" class="px-6 py-3 border-r">
                      Actions
                    </th>
                  </thead>
                  <tbody>
                    <tr v-for="(survey,index) in surveys" :key="index">
                      <td class="px-6 py-4 border-r">
                        <p>
                          <strong>{{survey.id}} : {{survey.content.describing_name}} </strong>
                        </p>
                        <p>
                          {{ formatDateTime(survey.content.created_date) }}
                        </p>
                      </td>
                      <td class="px-6 py-4 border-r">
                        <p class="mt-2 mb-2">
                          <button @click="goNext(survey.id)" class="mt-2 mb-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-lg">
                            Edit
                          </button>
                        </p>
                        <p class="mt-2 mb-2">
                          <button @click="showConfirmDialogPopup(survey.id)" class="mt-2 mb-2 bg-green-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-lg">
                            Upload
                          </button>
                        </p>
                        <p class="mt-2 mb-2">
                          <button @click="deleteSurvey(survey.id)" class="mt-2 mb-2 bg-red-500 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded-lg">
                            Delete
                          </button>
                        </p>
                      </td>
                    </tr>

                  </tbody>
                </table>

              </div>
              <div class="mt-4 mb-4">
                <button @click="back" class="bg-gray-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-lg">Back</button>
              </div>
            </div>
          </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
        showConfirmDialog : false,
        showDeleteDialog : false,
        db :null,
        survey_changeable : true,
        selected_survey_id : null,
        surveys : null,
    }
  },
  computed: {
    online: function(){
      return this.$store.getters.online;
    },
    user_id : function(){
      return this.$store.getters.user_id;
    },
    token : function(){
      return this.$store.getters.token;
    }
  },
  mounted() {
    const request = window.indexedDB.open("SurveyDatabase", 2);
    request.onerror = (event) => {
      console.error("Database error: " + event.target.errorCode);
    };

    request.onsuccess = (event) => {
      this.db = event.target.result; // `this` refers to the Vue instance
      console.log("Database opened successfully");
      this.readSurveys();
    };

    request.onupgradeneeded = (event) => {
      this.db = event.target.result;
      if (!this.db.objectStoreNames.contains("SurveyDataStore")) {
        this.db.createObjectStore("SurveyDataStore", { keyPath: "id" });
        console.log("Survey Object store created");
      }
      if (!this.db.objectStoreNames.contains("AnswersDataStore")) {
        this.db.createObjectStore("AnswersDataStore", { keyPath: "id" });
        console.log("Answers Object store created");
      }
    };
  },
  methods: {
    formatDateTime(date){
      return new Date(date).toLocaleString();
    },
    back(){
      this.$router.push({ name: "home"});
    },
    createSurvey(){
      this.$router.push({ name: "choose-survey"});
    },
    goNext(id){
      this.$router.push({ name: "edit-survey", params: { survey_id: id } });
    },
    confirmDelete(){
      this.removeAnswerFromIndexDB(this.selected_survey_id);
      this.readSurveys();
      this.selected_survey_id = null;
      this.showDeleteDialog = false;
      this.$notify({
        title: "Delete Complete",
        text: "Successfully deleted survey!",
        type : 'success',
        duration : 500
      });
    },
    confirmPush(){
      console.log("Pushing to cloud");
      console.log("User is "+this.user_id);
      let surveys  = this.surveys;
      let the_survey = null;
      surveys.find((survey) => {
        if(survey.id === this.selected_survey_id){
          the_survey = survey;
        }
      });
      const formData = new FormData();
      let answered = true;
      let errors = [];
      the_survey.content.form_items.forEach((item) => {
        if(item.options.required===1){
          if(item.answer === null){
            answered = false;
            errors.push(item.label)
          }
          if ((item.type === "File" || item.type === "Signature") && (item.options.required===1)){
              if(!item.file){
                answered = false;
                errors.push(item.label);
              }
          }
        }
      });
      if(answered === false){
        let text = '';
        errors.forEach((error) => {
          text += "<p>" + error + "</p> ";
        });
        this.$notify({
          title: "Sync Failed",
          text: text +" <p>are</p> required fields!",
          type : 'error',
          duration : 500
        });
        return;
      }
      // Append non-file data
      if(answered){
        formData.append('survey_id', the_survey.content.id);
        formData.append('description', the_survey.content.describing_name);
        formData.append('purpose', the_survey.content.purpose);
        formData.append('lat', the_survey.content.lat);
        formData.append('lng', the_survey.content.lng);
        formData.append('type', the_survey.content.type);
        formData.append('created_by', this.user_id);
        // Handling form_items which might include file blobs
        console.log(the_survey.content.form_items);
        the_survey.content.form_items.forEach((item, index) => {
          if (item.file) {
            formData.append(`form_items[${index}][id]`, item.id);
            formData.append(`form_items[${index}][file]`, item.file ,`${item.options.fileName}.${item.file_extension}`);
            formData.append(`form_items[${index}][answer]` ,`${item.options.fileName}.${item.file_extension}`);
          } else {
              formData.append(`form_items[${index}][id]`, item.id);
              formData.append(`form_items[${index}][answer]`, item.answer);
          }
        });
        // eslint-disable-next-line no-unreachable
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
        axios.post('https://lots.blackwidow.org.za/api/survey', formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          console.log(response.data);
          this.showConfirmDialog = false;
          this.readSurveys();
          this.$notify({
            title: "Sync Complete",
            text: "Successfully uploaded survey!",
            type : 'success',
            duration : 500
          });
          this.removeAnswerFromIndexDB(this.selected_survey_id)
          this.selected_survey_id = null;
          this.readSurveys();
        }).catch((error) => {
          console.error(error);
          this.$notify({
            title: "Sync Failed",
            text: error.data.message,
            type : 'error',
            duration : 500
          });
        });
      }
    },
    removeAnswerFromIndexDB(id){
      if (!this.db) {
        console.error("Database is not initialized");
        return;
      }
      const transaction = this.db.transaction(["AnswersDataStore"], "readwrite");
      const objectStore = transaction.objectStore("AnswersDataStore");
      const request = objectStore.delete(id);

      request.onerror = (event) => {
        console.error("Could not delete data: ", event.target.error);
      };

      request.onsuccess = () => {
        console.log("Data deleted: ", id);
      };
    },
    deleteSurvey(id){
      this.selected_survey_id = id;
      this.showDeleteDialog = true;
    },
    showConfirmDialogPopup(id){
      this.selected_survey_id = id;
      this.showConfirmDialog = true;
    },
    readSurveys(){
      console.log("Reading Surveys");
      if (!this.db) {
        console.error("Database is not initialized");
        return;
      }
      const transaction = this.db.transaction(["AnswersDataStore"]);
      const objectStore = transaction.objectStore("AnswersDataStore");
      const request = objectStore.getAll();

      request.onerror = (event) => {
        console.error("Could not retrieve data: ", event.target.error);
      };

      request.onsuccess = () => {
        if (request.result) {
          this.surveys = request.result;
          console.log("Data retrieved: ", request.result);
        } else {
          console.log("No data found with id 1");
        }
      };

    }
  }

}
</script>

<style scoped>
.confirm-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 100;
}
</style>

