<template>
  <notifications :dangerously-set-inner-html="true" />
  <nav class="bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
      <a href="/home" class="flex items-center space-x-3 rtl:space-x-reverse">
        <img src="/img/icons/android-chrome-512x512.png" class="h-8" alt="Survey APP" />
        <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Siyaleader</span>
      </a>
      <button data-collapse-toggle="navbar-dropdown" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-dropdown" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
      </button>
      <div class="hidden w-full md:block md:w-auto" id="navbar-dropdown">
        <ul class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">

          <li v-if="this.$store.getters.token!==null">
            <a href="/" class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent" aria-current="page">Home</a>
          </li>
          <li v-if="this.$store.getters.token!==null">
            <a href="/my-surveys" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">My Surveys</a>
          </li>
          <li v-if="this.$store.getters.token!==null">
            <a href="/choose-survey" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Perform a Survey</a>
          </li>
          <li v-if="this.$store.getters.token!==null">
            <a href="#" @click="logOut" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Logout</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div id="app" class="bg-gray-100 dark:bg-gray-800">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return {
      menuOpen: false,
      isOnline: navigator.onLine
    }
  },
  components: {
  },
  mounted() {
    if(this.$store.getters.token===null){
      this.$router.push({ name: "login"})
    }
    this.updateOnlineStatus();
    console.log(process.env.VUE_APP_API_BASE_URL);
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  unmounted() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  computed:{
    user_id(){
      return this.$store.getters.user_id;
    }
  },
  methods:{
      toggleMenu() {
        this.menuOpen = !this.menuOpen;
        const mobileMenu = document.getElementById('mobile-menu');
        if (this.menuOpen) {
          mobileMenu.classList.remove('hidden');
        } else {
          mobileMenu.classList.add('hidden');
        }
      },
      updateOnlineStatus() {
        this.isOnline = navigator.onLine;
        this.$store.commit('UPDATE_ONLINE',this.isOnline);
      },
      logOut(){
        this.$store.dispatch('resetStore');
        localStorage.removeItem('user_id');
        localStorage.removeItem('token');
        this.$router.push({ name: "login"})
      }
  }
}
</script>


